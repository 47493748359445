export default defineNuxtPlugin(() => {
  return {
    provide: {
      vuelidateErrorsToStrings: (errors, singular = false) => {
        if (!errors?.length) {
          return []
        }
        if (singular) {
          return [errors[0].$message]
        } else {
          return errors.map((error) => {
            return error.$message
          })
        }
      },
    },
  }
})
