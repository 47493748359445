import { OVERVIEW_STEP_PATH, DATES_AND_STATES_STEP_PATH, PERILS_STEP_PATH, REVIEW_STEP_PATH } from '~/components/claims-admin/cat-tag-utils'

export default defineNuxtRouteMiddleware((to) => {
  // Middleware redirects related to the file-claim flow
  if (to.path.includes('file-claim')) {
    const { $store } = useNuxtApp()
    const contactsAndDamageTabsAreValid = !$store.state.claim.contactsTabHasErrors && !$store.state.claim.damageTabHasErrors
    // The user can be "out of bounds" in 2 ways - both require us to send them to the contacts page
    // 1) If contacts aren't populated for the claim flow, this signals empty state due to a page reload; the user must start over
    // 2) If a claim number exists for the claim flow, this signals that the claim has been submitted (and the user is probably navigating via back/forward browser buttons); the user must start over
    const userIsOutOfBounds = $store.state.claim.contacts.length === 0 || $store.state.claim.claimNumber !== ''
    const shouldRouteToContactsTab = (!(to.path.includes('contacts')) && userIsOutOfBounds) || (to.path.includes('review') && !contactsAndDamageTabsAreValid)

    if (shouldRouteToContactsTab) {
      const route = useRoute()
      $store.dispatch('claim/clearApplicationState')
      return navigateTo(`/policy/${route.params.policyNumber}/file-claim/contacts`)
    }
  }

  // Middleware redirects related to the Portal Admin CAT Tag add/update flow
  if (to.path.includes('cat-tags') && (to.path.includes(DATES_AND_STATES_STEP_PATH) || to.path.includes(PERILS_STEP_PATH) || to.path.includes(REVIEW_STEP_PATH))) {
    const { $store } = useNuxtApp()
    const route = useRoute()
    // check the route for the absence of the tagUuid param; if doesn't exist a CAT is being added
    // we only want to redirect users in the add flow
    const isCreateMode = !route.params.tagUuid
    // check that the "type" field in the overview step is empty
    // if so, we can assume the user hasn't visited and filled it out (AKA the app is newly-loaded and state is empty)
    const shouldRouteToOverviewTab = isCreateMode && $store.state.adminClaims[OVERVIEW_STEP_PATH].formData.type === ''

    if (shouldRouteToOverviewTab) {
      $store.dispatch('adminClaims/clearApplicationState')
      return navigateTo('/admin/claims/cat-tags/overview')
    }
  }
})
