<template>
  <div>
    <h3 class="font-medium text-xl">
      Now add the dates and corresponding states:
    </h3>
    <p class="text-gray-darker mt-1">
      Add at least one date to continue
    </p>
    <cat-date-range
      v-for="(date, index) in locationDates"
      :key="index"
      :index="index"
      :location-date="date"
      :is-editing="currentlyEditingId === index"
      @update-editing="(id) => currentlyEditingId = id"
      @cancel-update="cancelUpdate(index)"
    />
    <kudo-card
      class="border border-solid border-gray-light shadow-none mt-4"
    >
      <kudo-block>
        <kudo-button
          type="button"
          shape-variant="thin"
          class="w-auto self-start hover:bg-transparent disabled:text-gray"
          color-variant="flat"
          :disabled="currentlyEditingId !== null"
          @click="addNewDateRange"
        >
          <i class="fas fa-plus" />
          Add Date Range
        </kudo-button>
      </kudo-block>
    </kudo-card>
    <p
      v-for="(error, index) in $vuelidateErrorsToStrings(locationDatesErrors)"
      :key="index"
      class="mt-3 text-xs text-red"
    >
      {{ error }}
    </p>
  </div>
</template>

<script setup>
import { KudoButton, KudoCard, KudoBlock } from '@openly-engineering/kudo'
import { DATES_AND_STATES_STEP_PATH, OVERVIEW_STEP_PATH } from './cat-tag-utils'
import CatDateRange from '~/components/claims-admin/cat-date-range.vue'
const { v$ } = useCatTagValidation()

const { $store, $vuelidateErrorsToStrings } = useNuxtApp()
const currentlyEditingId = ref(null)

const locationDates = computed(() => { return $store.state.adminClaims[DATES_AND_STATES_STEP_PATH].formData.locationDates })
const catType = computed(() => { return $store.state.adminClaims[OVERVIEW_STEP_PATH].formData.type })
const locationDatesErrors = computed(() => v$.value[DATES_AND_STATES_STEP_PATH].locationDates.$errors.filter(error => error.$validator !== '$each'))

// Used to revert the previous state when the user edits an existing locationDate but then hits "cancel". We save a static version of the locationDate as soon as we enter edit mode, hence it is the "cached" data
let cachedLocationDate = {}

// Watch currentlyEditingId - when a new locationDate is edited, save state to "cache" so we can revert it if needed
watch(() => currentlyEditingId.value, (newVal, oldVal) => {
  if (oldVal === null && newVal !== null) {
    cachedLocationDate = { ...locationDates.value[currentlyEditingId.value] }
  }
})

// Catch user navigating away to another tab while a locationDate is in editing mode
onBeforeRouteUpdate((_, __, next) => {
  const currentlyEditing = currentlyEditingId.value
  // Check whether a locationDate is currently being edited
  if (currentlyEditing !== null) {
    // If so, show the confirmation modal
    const confirmation = window.confirm('Are you sure you want to leave this tab? By doing so you will lose any information you have filled in for this date range.')
    if (confirmation) {
      // If user proceeds, delete or revert cat being edited
      cancelUpdate(currentlyEditing)
      next()
      return
    } else {
      // If user cancels, stay on the current tab
      next(false)
      return
    }
  }
  // If locationDate is not being edited proceed with navigation
  next()
})

function cancelUpdate(index) {
  if (!locationDates.value[index].saved) {
    // If the locationDate being edited hasn't been saved, delete this locationDate entirely
    $store.commit('adminClaims/removeLocationDate', index)
  } else {
    // Revert the changes by updating the location date with the cache data
    $store.commit('adminClaims/updateLocationDate', { locationDate: cachedLocationDate, index: currentlyEditingId.value })
  }
  currentlyEditingId.value = null
  cachedLocationDate = {}
}

function addNewDateRange() {
  $store.commit('adminClaims/addLocationDate', {
    states: [],
    type: catType.value,
    startDate: '',
    endDate: '',
    saved: false, // this value lets us know if a locationDate is newly-created and hasn't been saved yet
  })
  currentlyEditingId.value = locationDates.value.length - 1
}
</script>
