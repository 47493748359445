<template>
  <div class="flex flex-col gap-y-4">
    <section>
      <h2 class="text-xl">
        Start by providing some details:
      </h2>
    </section>
    <kudo-card>
      <kudo-block>
        <kudo-form-control :errors="$vuelidateErrorsToStrings(typeErrors)">
          <kudo-radio-group
            v-model="type"
            label-text="Who declared the CAT?"
            :radio-options="tagTypeOptions"
            :show-radio-control="false"
            :has-border="true"
            :radio-list-classes="[
              'grid gap-4 text-center grid-cols-1 md:grid-cols-2 lg:grid-cols-4'
            ]"
            :disabled="isEditMode"
            required
            @update:model-value="clearPcsNumber"
          />
          <kudo-helper-text
            class="mt-1"
            :helper="radioGroupHelper"
          />
        </kudo-form-control>
        <kudo-expand-transition v-if="shouldShowPcsNumberInput">
          <kudo-form-control
            class="sm:w-72 border-l-2 border-plum pl-4"
            :errors="$vuelidateErrorsToStrings(pcsNumberErrors)"
          >
            <p class="font-medium mb-4">
              What is the CAT Number?
              <span class="text-red">*</span>
            </p>
            <kudo-input
              v-model="pcsNumber"
              label-text="CAT number"
              @input="debouncePcsNumberCheck"
            />
          </kudo-form-control>
        </kudo-expand-transition>
      </kudo-block>
    </kudo-card>
    <kudo-card>
      <kudo-block>
        <p class="font-medium mb-4">
          What is the CAT description?
          <span class="text-red">*</span>
        </p>
        <kudo-form-control
          class="sm:w-72"
          :errors="$vuelidateErrorsToStrings(descriptionErrors)"
        >
          <kudo-input
            v-model="description"
            :aria-invalid="descriptionErrors.length"
            label-text="Details"
            placeholder="E.g. Wind & Hail"
            aria-placeholder="E.g. Wind & Hail"
            :maxlength="11"
          >
            <template #label-utility>
              <div class="flex justify-end grow text-gray-dark text-normal">
                {{ description.length }} / 11
              </div>
            </template>
          </kudo-input>
        </kudo-form-control>
      </kudo-block>
    </kudo-card>
  </div>
</template>

<script setup>
import { KudoFormControl, KudoCard, KudoBlock, KudoRadioGroup, KudoHelperText, KudoExpandTransition, KudoInput } from '@openly-engineering/kudo'
import { OVERVIEW_STEP_PATH, tagTypeOptions, pcsTypeOption, EDIT_MODE } from './cat-tag-utils'
import useCatTagValidation from '~/composables/useCatTagValidation'

const { $store } = useNuxtApp()
const { getFieldErrors, checkPcsNumberIsUnique } = useCatTagValidation()

const isEditMode = computed(() => $store.state.adminClaims.mode === EDIT_MODE)
const radioGroupHelper = computed(() => isEditMode ? 'This selection cannot be changed.' : 'This selection cannot be changed later.')

const type = computed({
  get() { return $store.state.adminClaims[OVERVIEW_STEP_PATH].formData.type },
  set(val) { $store.commit('adminClaims/setTagType', val) },
})

const pcsNumber = computed({
  get() { return $store.state.adminClaims[OVERVIEW_STEP_PATH].formData.pcsNumber },
  set(val) { $store.commit('adminClaims/setPcsNumber', val) },
})

const description = computed({
  get() { return $store.state.adminClaims[OVERVIEW_STEP_PATH].formData.description },
  set(val) { $store.commit('adminClaims/setDescription', val) },
})

const shouldShowPcsNumberInput = computed(() => $store.getters['adminClaims/shouldShowPcsNumber'])
const typeErrors = computed(() => getFieldErrors(OVERVIEW_STEP_PATH, 'type'))
const pcsNumberErrors = computed(() => getFieldErrors(OVERVIEW_STEP_PATH, 'pcsNumber'))
const descriptionErrors = computed(() => getFieldErrors(OVERVIEW_STEP_PATH, 'description'))

const clearPcsNumber = (typeValue) => {
  if (typeValue !== pcsTypeOption.value) {
    $store.commit('adminClaims/setPcsNumber', '')
  }
}

let timer
function debouncePcsNumberCheck() {
  clearTimeout(timer)
  timer = setTimeout(checkPcsNumberIsUnique, 800)
}

</script>
