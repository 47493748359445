import urql, { cacheExchange, fetchExchange, mapExchange } from '@urql/vue'
import { authExchange as AuthExchange } from '@urql/exchange-auth'
import ErrorHandler from '~/apollo/error-handler'
import hasValidAccessToken from '~/middleware/auth-utils/hasValidAccessToken'
import silentlyAuthenticateWithAuth0 from '~/middleware/auth-utils/silentlyAuthenticateWithAuth0'

function isAuthenticationError(errorContext) {
  const isUnauthenticated = errorContext?.graphQLErrors?.some(gqlError => gqlError.extensions.code === 'UNAUTHENTICATED')

  Boolean(isUnauthenticated || errorContext.response?.status === 401)
}

// Use Auth0 to retrieve the updated token silently and update Apollo Client with the new token.
async function getAuthToken() {
  const { $auth, $bugsnag } = useNuxtApp()

  const silentAccessToken = await silentlyAuthenticateWithAuth0($auth, $bugsnag)

  if (!silentAccessToken) {
    // Send user to Auth0 login page
    $auth.authorize({})
    return
  }

  const { onLogin } = useApollo()

  // `onLogin` updates the auth token cookie.
  // The auth token cookie will still need to be updated here once Apollo is completely removed from Portal Next.
  await onLogin(silentAccessToken)

  return silentAccessToken
}

function getAuthExchange(authTokenName) {
  return AuthExchange((utils) => {
    return {
      addAuthToOperation(operation) {
        const token = useCookie(authTokenName)

        if (!token.value) {
          return operation
        }

        return utils.appendHeaders(operation, {
          Authorization: `Bearer ${token.value}`,
        })
      },
      willAuthError() {
        const token = useCookie(authTokenName)
        return !hasValidAccessToken(token.value)
      },
      // Returning `true` from `didAuthError` will result in `refreshAuth` below being called by the exchange.
      didAuthError: (errorContext, _operation) => isAuthenticationError(errorContext),
      // When `refreshAuth` is called for an operation URQL's auth exchange automatically flags the operation as having its authentication refresh attempted.
      // After this function runs the auth exchange adds the operation back into the request queue to retry to request.
      // The auth exchange will only call `refreshAuth` if the operations has NOT already be flagged for attempting an authentication refresh.
      // This means that each operation that fails due to authentication will only automatically retry once instead of creating an infinite loop.
      // There is also a retry exchange that is maintained by URQL. However, that exchange is for retrying requests NOT caused by failing to authenticate.
      refreshAuth: async() => await getAuthToken(),
    }
  })
}

export default defineNuxtPlugin((nuxtApp) => {
  const { public: config } = useRuntimeConfig()

  const authExchange = getAuthExchange(config.authTokenName)

  nuxtApp.vueApp.use(urql, {
    url: config.graphqlServerEndpoint,
    // The order of exchanges matters as synchronous exchanges must be placed before asynchronous exchanges.
    // See https://commerce.nearform.com/open-source/urql/docs/advanced/authoring-exchanges/#synchronous-first-asynchronous-last for details.
    exchanges: [
      cacheExchange,
      mapExchange({
        onError(errorContext, operation) {
          if (isAuthenticationError(errorContext)) {
            nuxtApp.$store.dispatch('self/logout')
          }

          // `skipGlobalErrorHandling` is an option that can be provided to clients and individual URQL requests.
          // If true then skip the global error handler behavior.
          if (operation.context.skipGlobalErrorHandling) {
            return
          }

          // Additionally, an array `skipGlobalErrorForHttpStatusCodes` can be provided
          // If provided and a status code matches, we will skip the global error handler
          const matches = errorContext.graphQLErrors.some((err) => {
            return operation.context.skipGlobalErrorForHttpStatusCodes?.includes(err.extensions.response.status)
          })
          if (matches) {
            return
          }

          // TODO: Once Apollo Client is removed from Portal Next these error handling functions should be refactored to avoid this unclear mapping.
          ErrorHandler(errorContext, nuxtApp)
        },
      }),
      authExchange,
      fetchExchange,
    ],
  })
})
