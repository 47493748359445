import { POLICY_NOT_FOUND } from '~/apollo/error-codes'

function isIDError(gqlError) {
  const code = gqlError.extensions?.code

  if (!code) { return { valid: false } }

  return {
    valid: code === POLICY_NOT_FOUND,
    redirect: true,
    errorContext: {
      message: 'The policy you\'re trying to reach does not exist.',
    },
  }
}

function isFromCore(gqlError) {
  const source = gqlError.extensions?.source
  if (!source) { return { valid: false } }

  const response = {
    valid: source === 'CORE',
    redirect: false,
  }

  const errorMessage = getErrorMessage(gqlError)

  if (errorMessage) {
    response.errorContext = {
      message: errorMessage,
    }
  }

  return response
}

function getErrorMessage(gqlError) {
  const { error: errorResponse, reasons } = gqlError?.extensions?.response?.data

  if (!errorResponse) {
    return
  }

  let errorMessage = errorResponse

  if (!reasons) {
    return errorMessage
  }

  // If there are API validation errors, add each validation error reason to the errorMessage.
  const reasonKeys = Object.keys(reasons)

  errorMessage = `${errorResponse}: `

  for (const [reasonIndex, reason] of reasonKeys.entries()) {
    errorMessage += `${reasons[reason].join(', ')}`

    if (reasonIndex + 1 < reasonKeys.length) {
      errorMessage += ', '
    }
  }

  return errorMessage
}

export default [
  isIDError,
  // This must go below all core errors that we want a specific response for
  isFromCore,
]
