import useVuelidate from '@vuelidate/core'
import { useQuery } from '@urql/vue'
import { required, requiredIf, helpers, minLength } from '@vuelidate/validators'
import generateGrpcRequestId from '~/shared/generateGrpcRequestId'
import LIST_TAGS from '~/graphql/queries/listTags.gql'
import { OVERVIEW_STEP_PATH, DATES_AND_STATES_STEP_PATH, PERILS_STEP_PATH, REVIEW_STEP_PATH, stepOrder } from '~/components/claims-admin/cat-tag-utils.js'

/**
 * Holds vuelidate instance outside of composable function to persist across re-renders.
 */
let v$ = null

/**
 * Track if entire form was validated.
 */
const isFormValidated = ref(false)

/**
 * Track errors from external sources (e.g. gql queries)
 */
const $externalResults = ref({})

/**
 * Holds original pcs number value (from queried Cat) when in edit mode
 */
const originalPcsNumber = ref(null)

/**
 * Track when user attempts to go to next step using buttons in summary panel. Used to display error banner.
 */
const nextAttempted = ref(false)
/**
 * Composable to manage form validation for Cat Tags.
 * - Uses a single Vuelidate instance for all steps.
 * - Ensures `v$` persists across navigation in create & edit pages.
 * - Supports single field, step and full-form validation.
 * - Watches for changes in current step path and validates the previous step when navigating.
 * - Resets validation and clears state when leaving the flow.
 * - Computes whether a tab should be disabled based on validation state.
 * - Computes whether a tab should display a warning icon (invalid + dirty).
 * - Computes whether a tab should display a success icon (valid + dirty)
 */
export default function useCatTagValidation() {
  const { $store, $dayjs, $router, $appLock } = useNuxtApp()

  const isDataLoaded = computed(() => $store.state.adminClaims.isDataLoaded)
  const userId = computed(() => $store.state.self.id)
  const grpcUuid = generateGrpcRequestId(userId.value)
  const variables = ref({ catNumber: '' })

  const { data: catData, executeQuery: executeCatNumberDuplicateQuery } = useQuery({
    query: LIST_TAGS,
    variables: variables.value,
    requestPolicy: 'network-only',
    context: {
      fetchOptions: {
        headers: {
          'Grpc-metadata-X-Request-ID': grpcUuid,
        },
      },
    },
    pause: true, // Do not run this query until we run executeQuery
  })

  /**
   * Checks what the user has entered for pcsNumber and updates Vuelidate depending on the server response.
   * Utilizes $externalResults - see example:
   * https://vuelidate-next.netlify.app/advanced_usage.html#external-results-with-composition-api
   */
  const checkPcsNumberIsUnique = async() => {
    v$.value.$clearExternalResults()
    const isPcsNumberValid = v$?.value[OVERVIEW_STEP_PATH]?.pcsNumber?.$silentErrors.length < 1
    if (isPcsNumberValid) {
      const pcsNumber = $store.state.adminClaims[OVERVIEW_STEP_PATH].formData.pcsNumber
      // account for editing flow by checking if the original pcs number (AKA from queried data)
      // matches the pcs number that is currently entered
      if (!pcsNumber || pcsNumber === originalPcsNumber.value) { return true }

      variables.value.catNumber = pcsNumber
      await executeCatNumberDuplicateQuery()
      const tagsArray = catData?.value?.listTags?.results
      const foundTag = tagsArray?.length > 0

      const errors = { [OVERVIEW_STEP_PATH]: { pcsNumber: ['This number already exists.'] } }

      // if the request resulted in a cat tag, update $external results and validate the entire step ahead of the navigation event
      if (foundTag) {
        $externalResults.value = errors
        validateStep(OVERVIEW_STEP_PATH)
      }
    }
  }

  /**
   * Validation Rules for all steps
   */
  const validationRules = {
    [OVERVIEW_STEP_PATH]: {
      description: { required },
      type: { required },
      pcsNumber: { required: requiredIf(() => $store.getters['adminClaims/shouldShowPcsNumber']) },
    },
    [DATES_AND_STATES_STEP_PATH]: {
      locationDates: {
        required: helpers.withMessage('At least one date range is required.', required),
        minLength: minLength(1),
        $each: helpers.forEach({
          states: {
            required: helpers.withMessage('States are required.', required),
          },
          startDate: {
            required: helpers.withMessage('Start date is required.', required),
            beforeStartDate: helpers.withMessage(
              'Must be before or same as end date.',
              (value, $model) => $dayjs(value).isBefore($dayjs($model.endDate).add(1, 'day'))
            ),
          },
          endDate: {
            required: helpers.withMessage('End date is required.', required),
            afterStartDate: helpers.withMessage(
              'Must be after or same as start date.',
              (value, $model) => $dayjs(value).isAfter($dayjs($model.startDate).subtract(1, 'day'))
            ),
          },
        }),
      },
    },
    [PERILS_STEP_PATH]: {
      perilUuids: {
        required: helpers.withMessage('At least one peril is required.', required),
        minLength: minLength(1),
      },
    },
  }

  /**
   * Reactive computed form structure for all steps (excluding the review step).
   */
  const form = computed(() => ({
    [OVERVIEW_STEP_PATH]: $store.state.adminClaims[OVERVIEW_STEP_PATH].formData,
    [DATES_AND_STATES_STEP_PATH]: $store.state.adminClaims[DATES_AND_STATES_STEP_PATH].formData,
    [PERILS_STEP_PATH]: $store.state.adminClaims[PERILS_STEP_PATH].formData,
  }))

  /**
   * Single Vuelidate instance for all steps. Initialize v$ once to persist across navigation.
   */
  if (v$ === null) {
    v$ = useVuelidate(validationRules, form, { $externalResults })
  }

  /**
   * Computed property to get the current step from the router.
   */
  const currentStepPath = computed(() => $router.currentRoute.value.params.stepName)

  /**
   * Computed property to get the current step index based on stepOrder array and current step path value.
   */
  const currentStepIndex = computed(() => stepOrder.indexOf(currentStepPath.value))

  /**
   * Computed property to check if current step is last step.
   */
  const isLastStep = computed(() => currentStepIndex.value === stepOrder.length - 1)

  /**
   * Updates the `originalPcsNumber` ref with the current pcs number value from the form at Overview step.
   */
  const updateOriginalPcsNumber = () => {
    originalPcsNumber.value = form.value[OVERVIEW_STEP_PATH].pcsNumber
  }

  /**
   * Resets the validation instance (`v$`), state and other references.
   * This should be called when the user exits the CAT Tag step flow.
   */
  const resetFormValidation = () => {
    if (v$) {
      v$.value.$clearExternalResults()
    }
    v$ = null
    originalPcsNumber.value = null
    isFormValidated.value = false
    $store.dispatch('adminClaims/clearApplicationState')
  }

  /**
   * Validates entire form (aka all steps).
   * Updates `dirty` and `invalid` values for each step when validation is complete.
   */
  const validateEntireForm = async() => {
    await v$.value.$validate()
    isFormValidated.value = true
  }

  /**
   * Retrieves the validation object for a specific step.
   * @param {String} stepPath - The path of the step in url (based on constants defined in utils).
   * @returns {Object} The Vuelidate validation object for the specified step.
   */
  const getStepValidationObj = (stepPath) => {
    return v$?.value[stepPath]
  }

  /**
   * Checks if a specific step is marked as dirty (if the user has interacted with it).
   * @param {String} stepPath - The path of the step in url (based on constants defined in utils)
   * @returns {Boolean} 'true if the step is dirty, 'false' otherwise.
   */
  const isStepDirty = (stepPath) => { return v$?.value[stepPath]?.$dirty || false }

  /**
   * Checks if a specific step is valid (passes all validation rules).
   * @param {String} stepPath - The path of the step in url (based on constants defined in utils)
   * @returns {Boolean} 'true' if the step is valid, 'false' otherwise.
   */
  const isStepValid = (stepPath) => { return !v$?.value[stepPath]?.$invalid || false }

  /**
   * Validates a specific step and returns whether it is valid.
   * @param {String} stepPath - The path of the step in url (based on constants defined in utils)
   * @returns {Boolean} 'true' if the step is valid, 'false' otherwise.
   */
  const validateStep = async(stepPath) => {
    const stepValidationObj = getStepValidationObj(stepPath)
    if (stepValidationObj) {
      await v$.value[stepPath].$validate()

      return isStepValid(stepPath)
    }
    // step has no validation so return true
    return true
  }

  /**
   * Retrieves validation errors for a specific field. This is used to display error messages in UI.
   * @param {String} stepPath - The path of the step in url (based on constants defined in utils).
   * @param {String} fieldName - The name of the field to check. Name based on formData keys in state.
   * @returns {Array<Object>} Array of validation error objects for the field.
   */
  const getFieldErrors = (stepPath, fieldName) => {
    const stepValidationObj = getStepValidationObj(stepPath)
    return stepValidationObj?.[fieldName]?.$errors || []
  }

  /**
   * Attempts to navigate to the next step in the step flow.
   * - Sets `nextAttempted` to true to indicate that a navigation attempt was made.
   *   - Used to display an error banner if navigation is blocked due to validation errors.
   * - If the current step is Perils and the Review step is disabled, the entire form is validated.
   * - Otherwise, only the current step is validated.
   * - If the next step is not disabled, navigates to the next step and resets `nextAttempted`.
   *
   * Used by summary panel and tab navigation to trigger validation
   *
   * @param {String} nextStepPath - The path of the step to navigate to
   * @returns {void} Awaits validation and routes to step if valid.
   */
  const navigateToNextStep = async(nextStepPath) => {
    nextAttempted.value = true
    // If the current step is perils and review is disabled, validate the entire form.
    // Otherwise, only validate the current step.
    if (currentStepPath.value === PERILS_STEP_PATH && isStepTabDisabled(REVIEW_STEP_PATH)) {
      await validateEntireForm()
    } else {
      await $appLock.lockUntil(validateStep(currentStepPath.value))
    }

    if (!isStepTabDisabled(nextStepPath).value) {
      $router.push(nextStepPath)
      nextAttempted.value = false
    }
  }

  // Tab Validation
  /**
   * Determines whether a step's tab should be disabled.
   * - Dates & States and Perils steps are disabled if Overview Step is invalid.
   * - Review Step is disabled if any of the other steps are invalid
   * @param {String} stepPath - The path of the step in url (based on constants defined in utils).
   * @returns {ComputedRef<Boolean>} 'true' if tab should be disabled, otherwise 'false'.
   */
  const isStepTabDisabled = stepPath => computed(() => {
    switch (stepPath) {
      case DATES_AND_STATES_STEP_PATH:
      case PERILS_STEP_PATH:
        return !isStepValid(OVERVIEW_STEP_PATH)
      case REVIEW_STEP_PATH:
        return !isStepValid(OVERVIEW_STEP_PATH) || !isStepValid(DATES_AND_STATES_STEP_PATH) || !isStepValid(PERILS_STEP_PATH)
      default:
        return false
    }
  })

  /**
   * Computed property that determines whether to show a warning icon for a step tab.
   * A warning icon is shown if the step is invalid and has been interacted with.
   * @param {String} stepPath - The path of the step in url (based on constants defined in utils).
   * @returns {ComputedRef<Boolean>} A computed ref that returns 'true' if the warning icon should be shown, otherwise 'false'.
   */
  const showStepTabWarning = stepPath => (computed(() => {
    return !isStepValid(stepPath) && isStepDirty(stepPath)
  }))

  /**
   * Computed property that determines whether to show a success icon for a step tab.
   * A success icon is shown if the step is valid and has been interacted with.
   * @param {String} stepPath - The path of the step in url (based on constants defined in utils).
   * @returns {ComputedRef<Boolean>} A computed ref that returns 'true' if the success icon should be shown, otherwise 'false'.
   */
  const showStepTabSuccess = stepPath => (computed(() => {
    return isStepValid(stepPath) && isStepDirty(stepPath)
  }))

  /**
   * Watches for changes in the current step path and validates the previous step when navigating.
   * If the user navigates away from the step flow, form validation is reset.
   */
  watch(currentStepPath, (newStepPath) => {
    if (!newStepPath) {
      resetFormValidation()
    }
  })

  /**
   * Watches the `isDataLoaded` value and triggers `updateOriginalPcsNumber` only when the data has just finished loading for the first time.
   * It detects the transition from `false` to `true` and calls `updateOriginalEmail` during that change.
   */
  watch(isDataLoaded, (newIsDataLoaded, oldIsDataLoaded) => {
    if (oldIsDataLoaded === false && newIsDataLoaded === true) {
      updateOriginalPcsNumber()
    }
  })

  return {
    v$,
    currentStepPath,
    currentStepIndex,
    nextAttempted,
    isLastStep,
    isFormValidated,
    validateEntireForm,
    validateStep,
    getFieldErrors,
    isStepTabDisabled,
    showStepTabWarning,
    showStepTabSuccess,
    navigateToNextStep,
    checkPcsNumberIsUnique,
  }
}
