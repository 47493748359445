import Overview from '~/components/claims-admin/overview.vue'
import DatesAndStates from '~/components/claims-admin/dates-and-states.vue'
import Perils from '~/components/claims-admin/perils.vue'
import Review from '~/components/claims-admin/review.vue'

export const CREATE_MODE = 'create'
export const EDIT_MODE = 'edit'

export const OVERVIEW_STEP_PATH = 'overview'
export const DATES_AND_STATES_STEP_PATH = 'dates-and-states'
export const PERILS_STEP_PATH = 'perils'
export const REVIEW_STEP_PATH = 'review'

export const stepOrder = [OVERVIEW_STEP_PATH, DATES_AND_STATES_STEP_PATH, PERILS_STEP_PATH, REVIEW_STEP_PATH]

export const urlPathToComponentMap = {
  [OVERVIEW_STEP_PATH]: Overview,
  [DATES_AND_STATES_STEP_PATH]: DatesAndStates,
  [PERILS_STEP_PATH]: Perils,
  [REVIEW_STEP_PATH]: Review,
}

export const pcsTypeOption = { label: 'PCS', value: 'pcs' }
export const openlyCatTypeOption = { label: 'Openly', value: 'openlyCat' }

export const tagTypeOptions = [pcsTypeOption, openlyCatTypeOption]

/**
 * This map determines the order of active perils based on cause of loss.
 * Used to sort activePerils array from List CAT perils query.
 */
export const causeOfLossOrderMap = new Map([
  ['Weather', 0],
  ['Water', 1],
  ['Fire', 2],
  ['Misc', 3],
  ['Liability', 4],
])

/**
 * Checks if passed param for stepName is found in array of expected steps.
 *
 * @param {string} param - String value for stepName param in URL.
 * @returns {boolean} Returns `true` if stepName is found in array of expected steps, otherwise `false`.
 */
export const validStepNameParam = (param) => {
  return stepOrder.includes(param)
}
