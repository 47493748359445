import { OVERVIEW_STEP_PATH, pcsTypeOption } from '~/components/claims-admin/cat-tag-utils.js'

export default {
  shouldShowPcsNumber(state) {
    return state[OVERVIEW_STEP_PATH].formData.type === pcsTypeOption.value
  },
  getFormDataByStepPath(state) {
    return stepPath => state[stepPath].formData
  },
  perilsLookup(state) {
    const map = new Map()
    state.activePerils.forEach((activePeril) => {
      activePeril.perils.forEach(peril => map.set(peril.uuid, peril.name))
    })
    return map
  },
}
