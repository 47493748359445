<template>
  <kudo-card class="mt-4 p-6">
    <div
      v-if="isEditing"
    >
      <div>
        <span v-if="!locationDate.saved">Add </span>
        <span v-else>Edit </span>Date Range
      </div>
      <div class="grid gap-x-4 mt-4">
        <kudo-form-control
          class="col-span-12 md:col-span-6 col"
          :errors="$vuelidateErrorsToStrings(startDateErrors, true)"
        >
          <kudo-datepicker
            v-model="startDate"
            placeholder="Select date"
            label-text="Start Date"
            :aria-invalid="startDateErrors.length"
            :max-date="$dayjs().toString()"
            required
          />
        </kudo-form-control>
        <kudo-form-control
          class="col-span-12 md:col-span-6"
          :errors="$vuelidateErrorsToStrings(endDateErrors, true)"
        >
          <kudo-datepicker
            v-model="endDate"
            placeholder="Select date"
            label-text="End Date"
            :aria-invalid="endDateErrors.length"
            :max-date="$dayjs().toString()"
            required
          />
        </kudo-form-control>
        <kudo-form-control
          class="col-span-12"
          :errors="$vuelidateErrorsToStrings(statesErrors)"
        >
          <kudo-multiselect
            v-model="states"
            :is-searchable="true"
            :options="stateOptions"
            label-text="Affected States"
            :aria-invalid="statesErrors.length"
          />
        </kudo-form-control>
        <kudo-form-control
          v-if="catType === 'pcs'"
          class="col-span-12"
        >
          <kudo-checkbox
            v-model="type"
            true-value="openlyCat"
            false-value="pcs"
            label-text="Openly Only"
          />
        </kudo-form-control>
        <div class="flex justify-end col-span-12">
          <kudo-button
            color-variant="secondary"
            class="mr-4"
            @click="emit('cancel-update')"
          >
            Cancel
          </kudo-button>
          <kudo-button
            color-variant="primary"
            @click="saveUpdate"
          >
            Save
          </kudo-button>
        </div>
      </div>
    </div>
    <div
      v-else
      class="flex justify-between"
    >
      <div
        class="flex flex-col justify-between font-medium"
      >
        <div>
          {{ $dayjs(locationDate.startDate).format('MM/DD/YYYY') }} - {{ $dayjs(locationDate.endDate).format('MM/DD/YYYY') }}
          <kudo-pill
            v-if="locationDate.type === 'openlyCat'"
            class="ml-2 border-none bg-gold-light"
          >
            <span class="min-w-6 text-center">Openly only</span>
          </kudo-pill>
        </div>
        <div class="mt-4">
          <div class="text-sm font-normal">
            States
          </div>
          <span
            v-for="(state, statesIndex) in locationDate.states"
            :key="statesIndex"
          >
            <kudo-pill
              class="mr-2 mt-1 border border-grey-darker"
            >
              {{ state }}
            </kudo-pill>
          </span>
        </div>
      </div>
      <div class="flex">
        <kudo-button
          color-variant="flat"
          class="!p-1 !py-0"
          :disabled="isEditing"
          @click="updateIsEditingId(index)"
        >
          Edit
        </kudo-button>
        <div class="w-px bg-gray mx-2 h-6 mt-2" />
        <kudo-button
          color-variant="flat"
          class="!p-1 !py-0"
          :disabled="isEditing"
          @click="deleteLocationDate(index)"
        >
          Delete
        </kudo-button>
      </div>
    </div>
  </kudo-card>
</template>

<script setup>
import { KudoButton, KudoCard, KudoMultiselect, KudoPill, KudoFormControl, KudoDatepicker, KudoCheckbox } from '@openly-engineering/kudo'
import { DATES_AND_STATES_STEP_PATH, OVERVIEW_STEP_PATH } from './cat-tag-utils'
import { GeoStatesKudoOptions } from '~/shared/geoStates'

const { $dayjs, $vuelidateErrorsToStrings, $store } = useNuxtApp()
const emit = defineEmits(['update-editing', 'cancel-update'])

const props = defineProps({
  locationDate: {
    type: Object,
    default: () => {},
  },
  isEditing: {
    type: Boolean,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
})

const vLocationDates = computed(() => v$.value[DATES_AND_STATES_STEP_PATH].locationDates)
const catType = computed(() => { return $store.state.adminClaims[OVERVIEW_STEP_PATH].formData.type })

const { v$ } = useCatTagValidation()
const stateOptions = GeoStatesKudoOptions.filter(state => state.value !== 'DC')

const startDate = computed({
  get() { return props.locationDate.startDate },
  set(value) { $store.commit('adminClaims/setStartDate', { index: props.index, value }) },
})

const endDate = computed({
  get() { return props.locationDate.endDate },
  set(value) { $store.commit('adminClaims/setEndDate', { index: props.index, value }) },
})

const states = computed({
  get() { return props.locationDate.states },
  set(value) { $store.commit('adminClaims/setStates', { index: props.index, value }) },
})

const startDateErrors = computed(() => vLocationDates.value.$dirty ? vLocationDates.value.$each?.$response?.$errors?.[`${props.index}`]?.startDate || [] : [])
const endDateErrors = computed(() => vLocationDates.value.$dirty ? vLocationDates.value.$each?.$response?.$errors?.[`${props.index}`]?.endDate || [] : [])
const statesErrors = computed(() => vLocationDates.value.$dirty ? vLocationDates.value.$each?.$response?.$errors?.[`${props.index}`]?.states || [] : [])

const type = computed({
  get() { return props.locationDate.type },
  set(value) {
    $store.commit('adminClaims/setLocationDateType', { index: props.index, value })
  },
})

function saveUpdate() {
  if (v$.value[DATES_AND_STATES_STEP_PATH].locationDates.$invalid) {
    v$.value[DATES_AND_STATES_STEP_PATH].locationDates.$touch()
    return
  }
  $store.commit('adminClaims/markLocationDateSaved', props.index)
  updateIsEditingId(null)
}

function updateIsEditingId(id) {
  emit('update-editing', id)
}

function deleteLocationDate(val) {
  $store.commit('adminClaims/removeLocationDate', val)
}
</script>
