/**
 * This is a map to all geographical states and their associated label. With this object
 * we can easily index and get relevant information based on the state
 * abbreviation.
 * @type {{ [index: string]: { label: string } }}
 */
export const GeoStates = {
  AL: {
    label: 'Alabama',
  },
  AK: {
    label: 'Alaska',
  },
  AZ: {
    label: 'Arizona',
  },
  AR: {
    label: 'Arkansas',
  },
  CA: {
    label: 'California',
  },
  CO: {
    label: 'Colorado',
  },
  CT: {
    label: 'Connecticut',
  },
  DE: {
    label: 'Delaware',
  },
  DC: {
    label: 'District of Columbia',
  },
  FL: {
    label: 'Florida',
  },
  GA: {
    label: 'Georgia',
  },
  HI: {
    label: 'Hawaii',
  },
  ID: {
    label: 'Idaho',
  },
  IL: {
    label: 'Illinois',
  },
  IN: {
    label: 'Indiana',
  },
  IA: {
    label: 'Iowa',
  },
  KS: {
    label: 'Kansas',
  },
  KY: {
    label: 'Kentucky',
  },
  LA: {
    label: 'Louisiana',
  },
  ME: {
    label: 'Maine',
  },
  MD: {
    label: 'Maryland',
  },
  MA: {
    label: 'Massachusetts',
  },
  MI: {
    label: 'Michigan',
  },
  MN: {
    label: 'Minnesota',
  },
  MS: {
    label: 'Mississippi',
  },
  MO: {
    label: 'Missouri',
  },
  MT: {
    label: 'Montana',
  },
  NE: {
    label: 'Nebraska',
  },
  NV: {
    label: 'Nevada',
  },
  NH: {
    label: 'New Hampshire',
  },
  NJ: {
    label: 'New Jersey',
  },
  NM: {
    label: 'New Mexico',
  },
  NY: {
    label: 'New York',
  },
  NC: {
    label: 'North Carolina',
  },
  ND: {
    label: 'North Dakota',
  },
  OH: {
    label: 'Ohio',
  },
  OK: {
    label: 'Oklahoma',
  },
  OR: {
    label: 'Oregon',
  },
  PA: {
    label: 'Pennsylvania',
  },
  RI: {
    label: 'Rhode Island',
  },
  SC: {
    label: 'South Carolina',
  },
  SD: {
    label: 'South Dakota',
  },
  TN: {
    label: 'Tennessee',
  },
  TX: {
    label: 'Texas',
  },
  UT: {
    label: 'Utah',
  },
  VT: {
    label: 'Vermont',
  },
  VA: {
    label: 'Virginia',
  },
  WA: {
    label: 'Washington',
  },
  WV: {
    label: 'West Virginia',
  },
  WI: {
    label: 'Wisconsin',
  },
  WY: {
    label: 'Wyoming',
  },
}

// March 2024 We no longer want these CT, PA, VA, WI, DE, OK
const statesToRemove = ['CT', 'PA', 'VA', 'WI', 'DE', 'OK']

const AuthorizedGeoStateOptions = Object.keys(GeoStates)
  .filter(key => !statesToRemove.includes(key))
  .reduce((obj, key) => {
    obj[key] = GeoStates[key]
    return obj
  }, {})

/**
 * This function takes the above list of geographical states and transforms it into the
 * appropriate data format for the `kudo-select` component.
 * * `label` - The full name of the state
 * * `value` - The state abbreviation
 * @returns {{ label: string, value: string }[]}
 */

export function mapToKudoSelectFormat(states) {
  const mapArray = Object.keys(states).map((key) => {
    return { label: states[key].label, value: key }
  })
  return mapArray
}

/**
 * This function takes the response of geographical states and transforms it into the
 * appropriate data format for the `kudo-select` component.
 * * `label` - The full name of the state
 * * `value` - The state abbreviation
 * inputs ["TN","AL"]
 * @returns {{ label: string, value: string }[]}
 */
export function mapAppointedStatesToKudoFormat(states) {
  const result = []
  Object.values(states).forEach((value) => {
    if (Object.keys(GeoStates).includes(value)) {
      result.push({ label: GeoStates[value].label, value })
    }
  })
  return result
}

/**
 * Similar to the function above but uses the AuthorizedStates list.
 * Note: transitional state will remove the redundant code once states are confirmed.
 * * `label` - The full name of the state
 * * `value` - The state abbreviation
 * inputs ["TN","AL"]
 * @returns {{ label: string, value: string }[]}
 */
export function mapAppointedAuthorizedStatesToKudoFormat(states) {
  const result = []
  Object.values(states).forEach((value) => {
    if (Object.keys(AuthorizedGeoStateOptions).includes(value)) {
      result.push({ label: AuthorizedGeoStateOptions[value].label, value })
    }
  })
  return result
}

/**
 * Because we are only currently needing the functionality of the kudo select
 * implementation, we are using that as the default export. If we begin to need
 * the actual map more, we can change that to be the default export.
 */
export const GeoStatesKudoOptions = mapToKudoSelectFormat(GeoStates)
/** Removed Selected States */
export const AuthorizedGeoStateKudoOptions = mapToKudoSelectFormat(AuthorizedGeoStateOptions)
