export default {
  clearApplicationState({ commit }) {
    commit('resetLocationDates')
    commit('resetPerilUuids')
    commit('resetActivePerils')
    commit('setMode', '')
    commit('setTagUuid', '')
    commit('setPcsNumber', '')
    commit('setDescription', '')
    commit('setIsDataLoaded', false)
    commit('setTagType', '')
  },
}
