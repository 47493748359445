<template>
  <div class="flex flex-col gap-y-4">
    <section>
      <h2 class="text-xl mb-1">
        Finally, select the perils that qualify:
      </h2>
      <p class="text-gray-darker">
        Add at least one peril to continue
      </p>
      <kudo-helper-text
        v-if="perilsErrors.length"
        class="mt-1"
        :errors="$vuelidateErrorsToStrings(perilsErrors)"
      />
    </section>
    <kudo-card
      v-for="(activePeril, index) in activePerils"
      :key="`${activePeril.causeOfLoss}-${index}`"
    >
      <kudo-block>
        <div class="flex gap-x-2 mb-4 ">
          <i
            v-if="Boolean(perilCauseOfLossIcon[activePeril.causeOfLoss])"
            :class="`self-center fa-regular ${perilCauseOfLossIcon[activePeril.causeOfLoss]}`"
          />
          <h3 class="font-medium">
            {{ activePeril.causeOfLoss }}
          </h3>
        </div>
        <kudo-checkbox
          v-for="peril in activePeril.perils"
          :key="peril.uuid"
          v-model="selectedPerils"
          class="font-medium h-14 [&:not(:last-child)]:mb-2 "
          :value="peril.uuid"
          :label-text="peril.name"
          has-border
        />
      </kudo-block>
    </kudo-card>
  </div>
</template>

<script setup>
import { KudoCard, KudoBlock, KudoCheckbox, KudoHelperText } from '@openly-engineering/kudo'
import { PERILS_STEP_PATH } from './cat-tag-utils'

const perilCauseOfLossIcon = {
  Fire: 'fa-fire',
  Liability: 'fa-gavel',
  Misc: 'fa-house-crack',
  Water: 'fa-water',
  Weather: 'fa-cloud-bolt',
}

const { $store } = useNuxtApp()
const { getFieldErrors } = useCatTagValidation()
const activePerils = computed(() => $store.state.adminClaims.activePerils)

const selectedPerils = computed({
  get() { return $store.state.adminClaims[PERILS_STEP_PATH].formData.perilUuids },
  set(val) { $store.commit('adminClaims/setPerilUuids', val) },
})

const perilsErrors = computed(() => getFieldErrors(PERILS_STEP_PATH, 'perilUuids'))
</script>
