import { DATES_AND_STATES_STEP_PATH, OVERVIEW_STEP_PATH, PERILS_STEP_PATH } from '~/components/claims-admin/cat-tag-utils'

export default () => ({
  mode: '',
  tagUuid: '',
  activePerils: [],
  [OVERVIEW_STEP_PATH]: {
    formData: {
      type: '',
      pcsNumber: '',
      description: '',
    },
  },
  [DATES_AND_STATES_STEP_PATH]: {
    formData: {
      // NOTE: before sending to API we will need to wrap each locationDate.startDate and locationDate.endDate in a dateRange object and convert these dates to yyyy-mm-dd format
      locationDates: [],
    },
  },
  [PERILS_STEP_PATH]: {
    formData: {
      perilUuids: [],
    },
  },
  isDataLoaded: false,
})
