import dayjs from 'dayjs'
import { causeOfLossOrderMap, DATES_AND_STATES_STEP_PATH, OVERVIEW_STEP_PATH, PERILS_STEP_PATH } from '~/components/claims-admin/cat-tag-utils'

export default {
  setMode(state, mode) {
    state.mode = mode
  },
  setIsDataLoaded(state, value) {
    state.isDataLoaded = value
  },
  setTagUuid(state, value) {
    state.tagUuid = value
  },
  setLocationDateType(state, value) {
    state[DATES_AND_STATES_STEP_PATH].formData.locationDates[value.index].type = value.value
  },
  setStates(state, value) {
    state[DATES_AND_STATES_STEP_PATH].formData.locationDates[value.index].states = value.value
  },
  setStartDate(state, value) {
    state[DATES_AND_STATES_STEP_PATH].formData.locationDates[value.index].startDate = value.value
  },
  setEndDate(state, value) {
    state[DATES_AND_STATES_STEP_PATH].formData.locationDates[value.index].endDate = value.value
  },
  setTagType(state, value) {
    state[OVERVIEW_STEP_PATH].formData.type = value
  },
  setPcsNumber(state, value) {
    state[OVERVIEW_STEP_PATH].formData.pcsNumber = value
  },
  setDescription(state, value) {
    state[OVERVIEW_STEP_PATH].formData.description = value
  },
  setActivePerils(state, activePerils) {
    state.activePerils = activePerils.sort((a, b) => {
      // Get index for cause of loss from order map.
      // If cause of loss is not found, put active peril at end of sorted array.
      const indexA = causeOfLossOrderMap.has(a.causeOfLoss) ? causeOfLossOrderMap.get(a.causeOfLoss) : activePerils.length
      const indexB = causeOfLossOrderMap.has(b.causeOfLoss) ? causeOfLossOrderMap.get(b.causeOfLoss) : activePerils.length

      return indexA - indexB
    })
  },
  resetActivePerils(state) {
    state.activePerils = []
  },
  // locationDates mutations
  addLocationDate(state, locationDate) {
    state[DATES_AND_STATES_STEP_PATH].formData.locationDates.push(locationDate)
  },
  updateLocationDate(state, value) {
    state[DATES_AND_STATES_STEP_PATH].formData.locationDates[value.index] = value.locationDate
  },
  markLocationDateSaved(state, index) {
    state[DATES_AND_STATES_STEP_PATH].formData.locationDates[index].saved = true
  },
  removeLocationDate(state, id) {
    const newLocationDates = state[DATES_AND_STATES_STEP_PATH].formData.locationDates.filter((_, index) => {
      return index !== id
    })
    state[DATES_AND_STATES_STEP_PATH].formData.locationDates = newLocationDates
  },
  resetLocationDates(state) {
    state[DATES_AND_STATES_STEP_PATH].formData.locationDates = []
  },
  // perilUuids mutations
  setPerilUuids(state, newPerilUuids) {
    state[PERILS_STEP_PATH].formData.perilUuids = newPerilUuids
  },
  resetPerilUuids(state) {
    state[PERILS_STEP_PATH].formData.perilUuids = []
  },
  populateTagData(state, tagData) {
    state.tagUuid = tagData.tagUuid
    state[OVERVIEW_STEP_PATH].formData.type = tagData.type
    state[OVERVIEW_STEP_PATH].formData.pcsNumber = tagData.pcsNumber
    state[OVERVIEW_STEP_PATH].formData.description = tagData.description
    state[DATES_AND_STATES_STEP_PATH].formData.locationDates = tagData.locationDates.map((date) => {
      return {
        startDate: dayjs(date.dateRange.startDate).toDate(),
        endDate: dayjs(date.dateRange.endDate).toDate(),
        states: date.states,
        type: date.type,
        saved: true,
      }
    })
    state[PERILS_STEP_PATH].formData.perilUuids = tagData.perilUuids

    state.isDataLoaded = true
  },
}
